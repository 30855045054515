import { render, staticRenderFns } from "./History.vue?vue&type=template&id=0077c56d&scoped=true&"
import script from "./History.vue?vue&type=script&lang=ts&"
export * from "./History.vue?vue&type=script&lang=ts&"
import style0 from "./History.vue?vue&type=style&index=0&id=0077c56d&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0077c56d",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QOptionGroup,QTimeline,QTimelineEntry,QBtn});
